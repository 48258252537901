import { FaJournalWhills } from "react-icons/fa";

export default {
  fr: {
    Sharing: "J'autorise le partage de mes données avec les autres participants.",
    FilipGoorden: "Filip Goorden",
    StevenTritsmans: "Steven Tritsmans",
    ThibautSteyaert: "Thibaut Steyaert",
    StephanieVandenEynde: "Stephanie Van den Eynde",
    VéroniqueOrens: "Véronique Orens", 
    EventNL: "EVENTS",
    Source: "Source",
    MeetDistrict: "MeetDistrict",
    JohnBogaerts: "John Alexander Bogaerts",
    Ichec: "Ichec",
    UnderTwentyFive: "Si vous avez moins de 25 ans l'inscription est gratuite.",
    PriceDemande: "Prix sur demande",
    BusinessDescript: "Offre dédiée à votre entreprise et vos employés.",
    InvitePeople: "Possibilité d’avoir des inviter à chaque événement",
    VisibleMag: "Visibilité dans nos B19 mag",
    AcessMobile: "Accès à notre application mobile",
    MeerDan: "Plus de 150 événements par an",
    Selectionner: "Sélectionner",
    Year: "an",
    BeneficiezAcces: "Bénéficiez d'un accès exclusif à tous nos événements.",
    InscriptionSuccesBusiness: "Vous serez contacter dans les 24 h.",
    TheMerode: "The Merode",
    ICHECAlumni: "ICHEC Alumni",
    JeuxDHiver: "Jeux d'hiver",
    SDWorx: "SD Worx",
    InvitationRequired: "Veuillez remplir le champ invitation",
    SofieBorghgraef: "Sofie Borghgraef",
    NathalieDucobu: "Nathalie Ducobu",
    Silversquare: "Silversquare",
    DavidLloyd: "David Lloyd",
    MaSourceLabel: "J'ai été invité(e) par :",
    MaSource: "Invitation",
    Day: "Jours",
    Hours: "Heures",
    Minutes: "Minutes",
    Seconds: "Secondes",
    ParticipateEvent: "Participez à tous nos événements en Belgique et au Luxembourg gratuitement",
    CotisationTitleHomePage: "COTISATION 600€ HTVA / AN",
    Langue: "LANGUE",
    GetBilling: "Je souhaite recevoir une facture par e-mail",
    PrivateModify: 'Privé',
    SociteyModify: 'Société',
    HaveYouTvaModify: 'Facturé en tant que :',
    Yes: 'Oui',
    No: 'Non',
    HaveYouTva: "Possédez-vous un numéro de TVA ?",
    EmailFacturationTo: "Entrez votre e-mail",
    EnterEmailFacturationTo: "Recevoir votre facture à une autre adresse e-mail",
    Po: "Entrez votre PO",
    EnterPo: "Entrez votre numéro PO si vous en avez un",
    Mister: "Monsieur",
    Miss: "Madame",
    AccountDeleteSucces : "Votre compte a bien été supprimé",
    Attention: "Attention !",
    Error: "Erreur",
    DeleteAccountTxt: "Attention vous êtes sur le point de supprimer votre compte et cette action est définitif. En supprimant votre compte vous supprimez l'entièreté de vos données. Etes-vous sûr de vouloir supprimer votre compte ?",
    DeleteButton: "SUPPRIMER MON COMPTE",
    ModalSuccesDeleteSubBody: "Votre demande d'inscription a bien été supprimé.",
    ModalSuccesDeleteSubTitle: "Demande d'inscription supprimé",
    MissignArgDeleteSub: "Un des champs n'est pas renseigné",
    MissignArgDeleteSub1: "L'e-mail ou le mot de passe est incorrect",
    MissignArgDeleteSub2: "Veuillez-vous connecter pour pouvoir supprimer votre compte",
    Faq: "FAQ",
    DeleteSubTitle: "Supprimer ma demande d'inscription",
    DeleteSubButton: "Supprimer",
    ValidationWaitting: "Validation en attente",
    ValidationWaittingExpl: "Le processus d'enregistrement de vos informations ne prend que quelques secondes. De notre côté, nous faisons tout notre possible pour traiter votre compte dans les plus brefs délais, souvent en quelques heures seulement. Cependant, il peut arriver que ceci mette plus de temps à cause du nombre important de demandes. Nous en sommes désolés. Une fois votre compte validé, vous serez averti par e-mail.",
    WhyAccountWaitTitle: "POURQUOI MON COMPTE EST EN ATTENTE DE VALIDATION ?",
    WhyAccountWaitExpl: "Si votre compte est en attente de validation, cela peut être dû à plusieurs raisons:",
    WhyAccountWaitExpl1: "- Votre demande n’a toujours pas été prise en charge.",
    WhyAccountWaitExpl2: "- Vous n’avez pas encore reçu la facture.",
    WhyAccountWaitExpl3: "- Vous n’avez pas encore payé la cotisation annuelle.",
    CancelSubTitle: "Annuler ma demande d'inscription",
    CancelSubExpl: "Lorsque vous supprimerez votre demande d'inscription, cela effacera définitivement toutes les informations personnelles qui y sont associées.",
    TimeToDeleteSub: "COMBIEN DE TEMPS ÇA PREND POUR SUPPRIMER UNE DEMANDE D’INSCRIPTION ?",
    DeleteDirectly: "La suppression de la demande d’adhésion est instantanée.",
    HowAskDeleteSub: "COMMENT DEMANDER LA SUPPRESSION DE MA DEMANDE D’INSCRIPTION ?",
    HowAskDeleteSubExpl: "Le processus est très simple. Si vous êtes sûr(e) de vouloir le faire, suivez simplement",
    HowAskDeleteSubExpl1: " ce lien pour commencer.",
    HowAskDeleteSubExpl2: " Ensuite, suivez les instructions.",
    DeleteAccountTitle: "Suppression du compte",
    DeleteAccountExpl: "Lorsque vous supprimerez votre compte cela effacera définitivement toutes les informations personnelles qui y sont associées. Vous ne pourrez plus accéder à votre compte et cette opération est irrévocable.",
    TimeToDeleteAccount: "COMBIEN DE TEMPS ÇA PREND POUR SUPPRIMER UN COMPTE ?",
    TimeToDeleteAccountExpl: "La suppression du compte est instantanée.",
    HowAskDeleteAccount: "COMMENT DEMANDER LA SUPPRESSION DE MON COMPTE ?",
    HowAskDeleteAccountExpl: "Effacer votre compte est une grande décision, mais le processus est très simple.",
    HowAskDeleteAccountExpl1: "Si vous êtes sûr(e) de vouloir le faire, connectez-vous simplement à votre compte depuis l’application ou le site web du B19 pour commencer.",
    HowAskDeleteAccountExpl2: "Ensuite, rendez-vous dans la partie profil et appuyer sur le bouton “Supprimer mon compte”.",
    HowAskDeleteAccountExpl3: "Enfin, ayez conscience que l'effacement du compte supprimera la totalité de vos données.",
    ErrorTvaApi: "Une erreur s'est produite, veuillez entrer vos informations manuellement",
    numberIcomplete: "Nº incomplet",
    NumeroRue: "Nº",
    DoubleEmail: "Doublon dans les e-mails renseignés",
    FieldObligatory: "champs obligatoires",
    Verify: "VÉRIFIER",
    ErrorTva: "Votre numéro de TVA est invalide",
    Part: "Nos partenaires",
    ConnexionTitle: "Connexion",
    becomeMember: "DEVENIR MEMBRE",
    becomeMemberYoung: "DEVENIR MEMBRE - 25 ANS GRATUIT",
    becomeMemberNoMaj: "Devenir membre",
    TipsSentence: `Vous pouvez désormais \n cliquer sur un des cercles pour \nafficher  les évènements phare\n  de votre région !`,
    bxl: "Région de Bruxelles-Capitale, Province du Brabant-Wallon, Province de Namur",
    luik: "Province de Liège",
    anv: "Province d'Anvers, Province du Limbourg",
    lux: "Grand-Duché de Luxembourg",
    fl: "Flandre",
    NextEvent: "Nos prochaines conferences",
    Complet: "COMPLET",
    Subscribe: "JE PARTICIPE",
    Ambassador: "Ambassadeurs",
    AgendaMin: "Agenda",
    DisplayEventOf: "Afficher les événements de",
    Soon: "Prochainement",
    FindByLanguage: "Rechercher par langue",
    FindByAdress: "Rechercher par adresse",
    Filter: "FILTRE",
    FiltreMin: "Filtre",
    FindTitleMAJ: "RECHERCHER",
    Annuler: "ANNULER",
    CancelMAJ: "ANNULER",
    SelectYourRegion: "Sélectionnez votre région",
    ErrorTryLater: "Une erreur est survenue veuillez réassayer plus tard",
    ErrorAlert: "Une erreur s'est produite",
    PaymentRequestMAJ: "PAYER",
    UnSubscribeMAJ: "SE DÉSINSCRIRE",
    SubscribeMaj: "S'INSCRIRE",
    EventTitle: "Événement",
    Description: 'Description',
    AddressDoublePoint: 'Adresse:',
    SponsorBy: 'Sponsorisé par',
    Inscription: 'Inscription',
    Succes: 'Réussi',
    EnterYourFirstName : 'Entrez-votre prénom',
    EnterYourLastName : 'Entrez-votre nom',
    EnterYourEmail : 'Entrez-votre e-mail',
    EnterYourTelNumber : 'Entrez-votre e-mail',
    FirstNameObligatory : 'Prénom',
    EmailObligatory : 'E-mail',
    LastNameObligatory : 'Nom',
    TelObligatory : 'Téléphone',
    TelNotObligatory : 'Téléphone',
    SelectRegion : "Sélectionnez votre région",
    Inscrit : "INSCRIT",
    Complet : "COMPLET",
    NosProchainesConférences : "Nos prochaines conférences",
    JeParticipe : "JE PARTICIPE",
    DevenirMembre : "DEVENIR MEMBRE",
    ApplicationTitre : "L'application B19",
    ApplicationDescription1 : "L'application du cercle d'affaires du 21ème siècle, le B19, permet à ses membres de participer à la vie du cercle et d'échanger efficacement avec toute la communauté B19.",
    ApplicationDescription2: "Outre la possibilité de consulter l'agenda des activités, de s'y inscrire en un simple geste et de consulter la liste des membres, le point central de cette application repose sur la fonctionnalité du 'carpooling' entre membres pour se rendre aux événements.",
    ApplicationDescription3: "Créez vos propres opportunités business à travers cet outil qui vous permet de rencontrer facilement les membres du B19 proches de vous !",
    Adhesion : "Adhésion",
    DevenirMembreQuestion : "Vous voulez devenir membre ?",
    Moins25ans : "-25 ans",
    Free: "Gratuit",
    UnePersonne : "1 personne",
    QuatrePersonnes : "4 personnes d'une même entreprise",
    AcheterMaintenant : "BUY NOW",
    Sponsor : "Sponsor",
    BienvenueB19 : "Bienvenue au B19 !",
    BienvenueDescription1 : "Découvrez un cercle d'affaires moderne qui vous donne accès à des conférences chaque semaine en breakfast et afterwork avec des intervenants passionnants dans une atmosphère conviviale.",
    BienvenueDescription2 : "Votre membership vous donne également accès à toutes les conférences du B19 Brabant Wallon, Liège, Antwerpen, Gent, Mons-Hainaut.",
    BienvenueDescription3 : "Vous souhaitez organiser votre anniversaire ou un meeting avec vos collaborateurs, nous vous proposons un espace évènementiel et des salles de réunion modulables selon vos desiderata.",
    ProposDeNous : "À PROPOS DE NOUS",
    ProposDeNousDescription : "Le B19 invite ses membres à participer à des activités au cours desquelles ils auront l'occasion d'élargir leur réseau professionnel et amical.",
    NotreApplication : "NOTRE APPLICATION",
    Social : "SOCIAL",
    NousConacter : "Nous contacter",
    Support : "SUPPORT",
    AutresSitesB19 : "Autres sites B19",
    Locattion : "Location",
    ToutDroits : "© 2023 | B19 All Rights Reserved |",
    ConditionsGenerales : " Conditions Générales ",
    FaitPar : "| Made by ",
    Filtre : "FILTRE",
    RechercherParLangue : "Rechercher par langue",
    RechercherParAdresse : "Rechercher par adresse",
    Annuler : "ANNULER",
    Rechercher : "RECHERCHER",
    Erreur : "Erreur",
    ErreurProduite : "Une erreur s'est produite",
    ReesayerPlusTard : "Veuillez réessayer plus tard",
    Payer : "PAYER",
    Sinscrire : "S'INSCRIRE",
    SeDesinscrire : "SE DÉSINSCRIRE",
    EvenementTitre : "Évènement",
    Description : "Description",
    Adresse : "Adresse:",
    SponsorPar : "Sponsorisé par",
    TypeInscription : "Type d'inscription",
    YoungPackTitle: "Young Pack",
    YoungPack : "Young Pack (moins de 25ans - 0€)",
    SingleTitle: 'Single',
    SinglePackAnnuel : "Single annuel (600€ HTVA)",
    SinglePackMensuel : "Single mensuel (12 x 55€ HTVA)",
    BusinessPackTitle: "Business Pack",
    BusinessPack : "Business Pack (4 personnes d'une même entreprise - 2000€ HTVA)",
    CoordonneesPrivees : "Coordonnées",
    Nom : "Nom",
    EntrezNom : "Entrez-votre nom",
    Prénom : "Prénom",
    EntrezPrenom : "Entrez-votre prénom",
    Email : "E-mail",
    EntrezEmail : "Entrez-votre e-mail",
    Telephone : "Téléphone",
    EnterTelephone : "Entrez-votre numéro de téléphone",
    Societe : "Société",
    EntrezSociete : "Entrez-votre société/forme juridique",
    NumeroTva : "Nº TVA",
    EntrezNumeroTva : "Entrez-votre numéro de TVA",
    CodePostal : "Code postal",
    EntrezCodePostal : "Entrez-votre code postal",
    Ville : "Ville",
    EntrezVille: "Entrez-votre ville",
    AdressePostale : "Adresse",
    EntrezAdressePostal : "Rue, Avenue, Boulevard",
    MaRegion : "Ma région",
    RegionIndiquez : "Indiquez votre région parmis nos clubs",
    Bxl : "Bruxelles, Brabant-Wallon, Namur",
    Luik : " Province de Liège",
    Anv : "Anvers, Limbourg",
    Fl : "Flandre",
    Lux : "Grand-duché de Luxembourg",
    DateNaissance : "Date de naissance",
    Jour : "JJ",
    Mois : "MM",
    Annee : "AAAA",
    ParrainMarraine : "Parrain/Marainne",
    EntrezParrainMarraine : "Entrez-votre parrain/marraine",
    VotreParrain: "Votre parrain/marraine : ",
    Collegue1 : "Collègue 1",
    Collegue2 : "Collègue 2",
    Collegue3 : "Collègue 3",
    MoyenPaiement : "Moyen de paiement",
    PaiementImmediat : "Paiement immédiat",
    Facture : "Facture",
    CliquezIciFacturation : " Cliquez ici si vos coordonnées de facturation sont différentes de vos coordonnées privées",
    CoordonneesFacturation : "Coordonnées de facturation",
    AdresseFacturation : "Adresse de facturation",
    EntrezAdresseFacturation : "Entrez-votre adresse de facturation",
    Condition : "Condition",
    EngagementFacture : "Je m'engage à régler la facture dès réception.",
    LuAccepteCondition1 : "J'ai lu et j'accepte les ",
    LuAccepteCondition2 : "conditions générales ",
    LuAccepteCondition3 : "d'utilisation",
    Continuer : "CONTINUER",
    Connexion : "Connexion",
    PlaceHolderLogin : "Login (adresse email)",
    PlaceHolderPassword : "Mot de passe",
    SeConnecter : "SE CONNECTER",
    ForgetPassword : "Mot de passe oublié ?",
    PayerEnLigne : "PAYER EN LIGNE",
    ErreurLogin : "Une erreur s'est produite, veuillez réessayer plus tard",
    NousConacterMsg : "Pour nous contacter par e-mail, veuillez remplir le formulaire ci-dessous.",
    VotreMessage : "Votre message",
    Envoyer : "ENVOYER",
    MotDePasseOublieTitre : "Récupération de votre mot de passe",
    MotDePasseOublieMessage : "Pour récupérer votre mot de passe, veuillez entrer votre e-mail ci-dessous...",
    TokenInvalide : "Mot de passe temporaire invalide",
    PasswordLength :"Votre mot de passe doit faire au minimum 5 caractères",
    PasswordPasIdentique : "Les mots de passes ne sont pas identiques",
    EnterToken : "Entrez votre mot de passe temporaire reçu par e-mail",
    CompleteForgetPassword : "Veuillez remplir les informations ci-dessous",
    EnterNewPassword : "Entrez votre nouveau mot de passe",
    ConfirmNewPassword : "Confirmez votre nouveau mot de passe",
    Confirmer : "CONFIRMER",
    MofifierMotDePasse : "Modifier mon mot de passe",
    TokenExpire : "Mot de passe temporaire expiré",
    VeuillezRecommencer : "Veuillez recommencer",
    Approuve : "Approuvé",
    MotDePasseBienModifie : "Votre mot de passe a bien été modifié.",
    EmailModifie: "Votre adresse e-mail a bien été modifiée.",
    GaleriePhoto : "Galerie Photo",
    Partager : "Partager",
    ChoisissezPartage : "Choisissez votre méthode de partage:",
    SearchSector : "Rechercher par secteur",
    SearchMember : "Rechercher un membre",
    PlaceHolderSearchMember : "Rechercher par nom/prénom ou société",
    ErrorEmail : "Ce membre n'a pas renseigné son e-mail",
    Home : "HOME",
    Agenda : "AGENDA",
    Member : "MEMBRE",
    Galerie : "GALERIE",
    Profil : "PROFIL",
    ProfilMin: "Profil",
    Connect : "SE CONNECTER",
    Disconnect : "SE DÉCONNECTER",
    MyProfil : "Mon Profil",
    PersonalInfo : "Informations Personnelles",
    ProInfo : "Informations Professionnelles",
    ComplInfo : "Informations Complémentaire",
    DateSociety : "Date de création de l'entreprise:",
    Localisation : "Localisation:",
    LocalisationSociety: "Localisation de la société",
    SizeSociety : "Taille de l'entrepise:",
    EntrerEmailPlaceholder: "Entrez votre nouvelle adresse e-mail",
    ReEntrerEmailPlaceholder: "Confirmez votre nouvelle adresse e-mail",
    ModifyButton : "MODIFIER",
    ModifyProfil : "MODIFIER MON PROFIL",
    ModifyProfilMin: "Modifier mon profil",
    ModifyPassword : "MODIFIER MON MOT DE PASSE",
    ModifyEmail : "MODIFIER MON E-MAIL",
    ModifyEmailMin : "Modifier mon e-mail",
    Commentray : "Commentaire éventuel ?",
    Poste: "Poste",
    Sector : "Secteur",
    SectorDescription : "Veuillez indiquer votre secteur d'activité",
    Enregistrer : "ENREGISTRER",
    NewPicture : "NOUVELLE PHOTO",
    CancelPayment : "PAIEMENT ANNULÉ",
    SuccesPayment : "PAIEMENT RÉUSSI",
    Inscription : "Inscription",
    Success : "Réussi",
    EmailAlreadySubscribe : "Votre e-mail est déjà inscrit à cet événement",
    EmailMember : "Votre e-mail est enregistré en tant que membre, veuillez-vous connecter.",
    EmailNotSame: "Les adresses e-mail ne sont pas identiques",
    NoEventIntoSection: "Aucune conférence pour le moment...",
    NoAmbassadorIntoSection: "Aucun ambassadeur pour le moment...",
    News: "Nouveauté !",
    Facturation: "Facture",
    Send: "Demande confirmé",
    SendMail: "Envoyé",
    FacturationSend: "Une facture vous sera envoyé dans les plus bref délais.",
    Cotisation: "Ordre de cotisation",
    CotisationMessage: "Vous n'êtes plus en ordre",
    CotisationIndication: "Afin de pouvoir continuer à participer aux événèment B19 veuillez-vous reconnecter.",
    InscriptionSucces: "Bienvenue au B19, un e-mail vous a été envoyé avec vos identifiants de connexion.",
    AskForAdhesion: "Demande d'adhésion",
    RegistrationTypeNotSelect: "Le type d'inscription n'a pas été sélectionné",
    FirstNameMissing: "Prénom manquant",
    Caract: "Pas de caractères spéciaux (@#{}..)",
    FirstNameNumber: "Votre prénom doit contenir entre 2 et 30 caractères",
    LastNameMissing : "Nom manquant",
    LastNameNumber : "Votre nom doit contenir entre 2 et 30 caractères",
    EmailMissing : "E-mail manquant",
    EmailInvalid : "E-mail invalide",
    EmailNumber : "Votre e-mail ne doit pas dépasser plus de 60 caractères",
    TelMissing : "Numéro de téléphone manquant",
    TelNumber : "Votre numéro de téléphone ne doit pas dépasser 25 chiffres",
    MessageMissing : "Veuiilez entrer votre message",
    MessageNumber : "Votre doit message contenir entre 40 et 500 caractères",
    ErrorVerif : "Une erreur est survenue lors de la vérification",
    EmailNotExist : "E-mail inexistant",
    PasswordMissing : "Mot de passe manquant",
    PasswordNotGood : "Mauvais mot de passe",
    BusinessNotApproved : "Votre inscription 'Business' n'a pas été approuvée",
    BusinessNotPay : "La personne en charge du pack 'Businnes' n'a pas approuvé son paiement",
    CoupleNotApproved : "Votre inscription 'Couple' n'a pas été approuvée",
    CoupleNotPay : "La personne en charge du pack 'Couple' n'a pas approuvé son paiement",
    ErrorContactStaff : "Veuillez contacter le staff B19, une erreur s'est produite avec votre compte",
    NotApproved : "Votre compte n'a pas été approuvé",
    NotPay : "Votre inscription n'a pas encore été approuvée",
    FormatDateNotGood : "Veuillez entrer une date au format JJ/MM/AAAA",
    DateFromFutur : "Il semblerait que vous soyez né dans le futur",
    AgeUnder25 : "Vous devez avoir moins de 25 ans pour le pack Young",
    RegistrationTypeNotSelect : "Le type d'inscription n'a pas été sélectionné",
    DateMissing : "Date manquante",
    SocietyMissing : "Nom de société manquante",
    TvaMissing : "Numéro de TVA manquant",
    ZipCodeMissing : "Code postal manquant",
    CityMissing : "Nom de ville manquante",
    StreetMissing : "Adresse manquante",
    RegionMissing : "Region manquante",
    ColleagueMissing : "Collègue manquant",
    ChoosePaymentMethod: "Veuillez choisir une méthode de paiement.",
    PaymentMethodNotSelect : "Moyen de paiement pas sélectionné",
    CheckBoxEngagement : "La case d'engagement de paiement de facture n'a pas été cochée",
    CheckBoxGeneralConditionOfUse : "La case des conditions général d'utilisation n'a pas été cochée",
    MaxNumberColleague : "Trois collègues sont requis pour une inscription 'Business'",
    EmailColleagueExist : "L'e-mail d'un des collègues est déjà existante",
    EmailExist : "E-mail déjà existant",
    EnterName : "Veuillez entrer un nom",
    NoAccessPage : "Vous n'avez pas les droits d'accéder à cette page",
    CotisationOk : "Vous êtes en ordre de cotisation",
    ErrorHappened : "Une erreur est survenue, veuillez vous connecter pour accéder à cette page ou réessayer plus tard",
    EnterEmail : "Veuillez entrer un e-mail",
    ErrorUpdateEmail : "Une erreur est survenue lors de la modification de votre e-mail",
    VerifyDate : "Veuillez vérifier votre année de naissance",
    AccesTokenMissing : "Une erreur s'est produite, veuiilez vous reconnecter",
    ImageErrorUpload : "Une erreur s'est produite avec l'upload de l'image",
    EnterPassword : "Veuillez entrer un mot de passe",
    PasswordLength : "Veuillez entrer au moins 6 caractères",
    Space: " ",
    TypeOfInscription: "Type d'inscription",
    TypeOfMotivation: "Pitch de motivation",
    TypeOfMotivationPlaceholder: "Nous avons trop de demandes de Young members. Nous te demandons donc de détailler tes motivations ici.",
    ErrorNetwork: "Erreur réseau, veuillez réessayer",
    ErrorStaff: "Un problème est survenue, veuillez contacter le staff B19",
    AlreadyOrderCotisation: "Il semblerait que vous êtes déjà en ordre de cotisation ou que vous n'avez pas les droits d'accéder à cette page",
    Send: "Envoyé",
    SendSucces: "Votre message a bien été envoyé.",
    MissingRegion: "Région manquante",
    Contact: "Contact",
    ContactMaj: "CONTACT",
    NousContacter: "Nous-contacter",
    NousContacterRegion: "Pour nous contacter par e-mail ou par téléphone, veuillez indiquer votre région.",
    Immobilier: "Immobilier",
    Banques: "Banques, assurances, finances",
    Industriel: "Industriel",
    It: "IT",
    Biens: "Biens et services divers aux entreprises(BtoB)",
    Commerces: "Commerces (BtoC)",
    Avocat: "Avocats et autres professions libérales",
    Consultance: "Consultance/formation",
    Autres: "Autres",
    EmailNotSave: "Ce membre n'a pas rensigné son e-mail",
    IndiquerSizeOfSociety: "Veuillez indiquer la taille de l'entreprise",
    less10 : "Moins de 10 travailleurs",
    between10: "Entre 10 et 50 travailleurs",
    to50: "  De 50 à 200 travailleurs",
    to200: "Plus de 200 travailleurs",
    Belgique: "Belgique",
    VerifyDatePlz: "Veuillez vérifier l'année",
    ProfilUpdate: "Votre profil a bien été mis à jour.",
    Parrainnage: "ME FAIRE PARRAINER",
    VoirPlus: "VOIR NOTRE AGENDA COMPLET",
    ErrorSharing : "Vous devez accepter le partage des données",
    PlaceholderInvitation: "Facultatif : indiquez qui vous a invité",
  },
  en: {
    ConnexionTitle: "Connected",
    becomeMember: "BECOME A MEMBER",
    TipsSentence: `Vous pouvez désormais \n cliquer sur un des cercles pour \nafficher  les évènements phare\n  de votre région !`,
    bxl: "Région de Bruxelles-Capitale, Province du Brabant-Wallon, Province de Namur",
    luik: "Province de Liège",
    anv: "Province d'Anvers, Province du Limbourg",
    lux: "Flandre occidentale, Flandre orientale",
    fl: "Grand-Duché de Luxembourg",
  },
  nl: {
    Sharing: "ik geef toestemming om mijn gegevens met de andere aanwezigen te delen.",
    FilipGoorden: "Filip Goorden",
    StevenTritsmans: "Steven Tritsmans",
    ThibautSteyaert: "Thibaut Steyaert",
    StephanieVandenEynde: "Stephanie Van den Eynde",
    VéroniqueOrens: "Véronique Orens",
    EventNL: "EVENEMENTEN",
    Source: "Bron",
    MeetDistrict: "MeetDistrict",
    JohnBogaerts: "John Alexander Bogaerts",
    Ichec: "Ichec",
    UnderTwentyFive: "Als je jonger bent dan 25, is de inschrijving gratis.",
    PriceDemande: "Prijzen op aanvraag",
    BusinessDescript: "Aanbod toegewijd aan uw bedrijf en uw werknemers.",
    InvitePeople: "Mogelijkheid om gasten te ontvangen bij elk evenement",
    VisibleMag: "Zichtbaarheid in onze B19-magazine",
    AcessMobile: "Toegang tot onze mobiele applicatie",
    MeerDan: "Meer dan 150 evenementen per jaar",
    Selectionner: "Selecteer",
    Year: "jaar",
    BeneficiezAcces: "Profiteer van exclusieve toegang tot al onze evenementen.",
    InscriptionSuccesBusiness: "Er wordt binnen 24 uur contact met u opgenomen.",
    TheMerode: "The Merode",
    ICHECAlumni: "ICHEC Alumni",
    JeuxDHiver: "Jeux d'hiver",
    SDWorx: "SD Worx",
    InvitationRequired: "Vul het uitnodigingsveld in",
    SofieBorghgraef: "Sofie Borghgraef",
    NathalieDucobu: "Nathalie Ducobu",
    Silversquare: "Silversquare",
    DavidLloyd: "David Loyd",
    MaSourceLabel: "Ik ben uitgenodigd door:",
    MaSource: "Uitnodiging",
    Day: "Dag",
    Hours: "Uren",
    Minutes: "Minuten",
    Seconds: "Seconden",
    ParticipateEvent: "Neem gratis deel aan al onze evenementen in België en Luxemburg",
    CotisationTitleHomePage: "LIDGELD 600€ EXCL. BTW / JAAR",
    Langue: "TAAL",
    GetBilling: "Ik wil graag een factuur per e-mail ontvangen",
    PrivateModify: 'Private',
    SociteyModify: 'Bedrijf',
    HaveYouTvaModify: 'Gefactureerd als:',
    Yes: 'Ja',
    No: 'Nee',
    HaveYouTva: "Heeft u een BTW-nummer?",
    EmailFacturationTo: "Voer uw e-mailadres in",
    EnterEmailFacturationTo: "Ontvang uw factuur op een ander e-mailadres",
    Po: "Voer uw PO nummer in",
    EnterPo: "Voer uw PO nummer in als u dat heeft",
    Mister: "Meneer",
    Miss: "Mevrouw",
    AccountDeleteSucces : "Uw account is verwijderd",
    Attention: "Pas op!",
    Error: "Fout",
    DeleteAccountTxt: "Houdt u er rekening mee dat u op het punt staat uw account te verwijderen en dat deze actie definitief is. Door uw account te verwijderen, verwijdert u al uw gegevens. Weet u zeker dat u uw account wilt verwijderen?",
    DeleteButton: "MIJN ACCOUNT VERWIJDEREN",
    ModalSuccesDeleteSubBody: "Uw aanvraag is verwijderd.",
    ModalSuccesDeleteSubTitle: "Verzoek om inschrijving geschrapt",
    MissignArgDeleteSub: "Een van de velden is niet ingevuld",
    MissignArgDeleteSub1: "Onjuiste e-mail of wachtwoord",
    MissignArgDeleteSub2: "Log in om uw account te verwijderen",
    Faq: "FAQ",
    DeleteSubTitle: "Mijn aanvraag verwijderen",
    DeleteSubButton: "Delete",
    ValidationWaitting: "Validatie in behandeling",
    ValidationWaittingExpl: "De registratie van uw gegevens neemt slechts enkele seconden in beslag. Wij van onze kant doen ons uiterste best om uw rekening zo snel mogelijk te verwerken, vaak binnen enkele uren. Het kan echter voorkomen dat dit langer duurt vanwege het grote aantal verzoeken. Wij verontschuldigen ons hiervoor. Zodra uw account is gevalideerd, wordt u per e-mail op de hoogte gebracht.",
    WhyAccountWaitTitle: "WAAROM IS MIJN REKENING IN AFWACHTING VAN VALIDATIE?",
    WhyAccountWaitExpl: "Als uw account nog moet worden gevalideerd, kan dit verschillende oorzaken hebben:",
    WhyAccountWaitExpl1: "- Uw aanvraag is nog steeds niet verwerkt.",
    WhyAccountWaitExpl2: "- U hebt de factuur nog niet ontvangen.",
    WhyAccountWaitExpl3: "- U hebt de jaarlijkse bijdrage nog niet betaald.",
    CancelSubTitle: "Mijn aanvraag annuleren",
    CancelSubExpl: "Wanneer u uw toepassing verwijdert, worden alle persoonlijke gegevens die ermee verband houden, permanent verwijderd.",
    TimeToDeleteSub: "HOE LANG DUURT HET OM EEN AANVRAAG TE VERWIJDEREN?",
    DeleteDirectly: "Het verwijderen van de applicatie is onmiddellijk.",
    HowAskDeleteSub: "HOE VRAAG IK DE VERWIJDERING VAN MIJN AANVRAAG AAN?",
    HowAskDeleteSubExpl: "Het proces is heel eenvoudig. Als je zeker weet dat je het wilt doen, volg dan",
    HowAskDeleteSubExpl1: " deze link om te beginnen.",
    HowAskDeleteSubExpl2: " Volg dan de instructies.",
    DeleteAccountTitle: "De rekening verwijderen",
    DeleteAccountExpl: "Wanneer u uw account verwijdert, worden alle persoonlijke gegevens die eraan gekoppeld zijn, permanent verwijderd. U zult niet langer toegang hebben tot uw account en deze operatie is onherroepelijk.",
    TimeToDeleteAccount: "HOE LANG DUURT HET OM EEN ACCOUNT TE VERWIJDEREN?",
    TimeToDeleteAccountExpl: "De verwijdering van de rekening is onmiddellijk.",
    HowAskDeleteAccount: "HOE VRAAG IK DE VERWIJDERING VAN MIJN ACCOUNT AAN?",
    HowAskDeleteAccountExpl: "Uw account verwijderen is een grote beslissing, maar het proces is heel eenvoudig.",
    HowAskDeleteAccountExpl1: "Als u zeker weet dat u dit wilt doen, logt u gewoon in op uw account via de B19-app of -website om aan de slag te gaan.",
    HowAskDeleteAccountExpl2: "Ga dan naar het profiel gedeelte en druk op de “Verwijder mijn account” knop.",
    HowAskDeleteAccountExpl3: "Tot slot, wees ervan bewust dat het verwijderen van de account al uw gegevens zal verwijderen.",
    ErrorTvaApi: "Er is een fout opgetreden, voer uw gegevens handmatig in",
    numberIcomplete: "Ontbrekend Nº",
    NumeroRue: "Nº",
    DoubleEmail: "Dubbele e-mails ingevoerd",
    FieldObligatory: "verplichte velden",
    Verify: "VERIFIËREN",
    ErrorTva: "Uw BTW-nummer is ongeldig",
    Part: "Onze partners",
    ConnexionTitle: "Connexion",
    becomeMember: "LID WORDEN",
    becomeMemberYoung: "YOUNG MEMBER GRATIS -25 LID WORDEN ",
    becomeMemberNoMaj: "Lid worden",
    TipsSentence: `U kunt nu op een van onze businessclubs\n klikken om de topevenementen \nin uw regio te ontdekken !`,
    bxl: "Brussels Hoofdstedelijk Gewest, Provincie Waals-Brabant, Provincie Namen",
    luik: "Provincie Luik",
    anv: "Provincie Antwerpen, Provincie Limburg",
    lux: "Groothertogdom Luxemburg",
    fl: "Vlaanderen",
    NextEvent: "Onze volgende conferenties",
    Complet: "VOLLEDIG",
    Subscribe: "IK NEEM DEEL",
    Ambassador: "Ambassadeurs",
    AgendaMin: "Agenda",
    DisplayEventOf: "Toon bijeenkomst van",
    Soon: "Binnenkort",
    FindByLanguage: "Rechercher par langue",
    FindByAdress: "Rechercher par adresse",
    Filter: "FILTER",
    FindTitleMAJ: "RECHERCHER",
    CancelMAJ: "ANNULER",
    SelectYourRegion: "Een regio selecteren",
    ErrorTryLater: "Une erreur est survenue veuillez réassayer plus tard",
    ErrorAlert: "Une erreur s'est produite",
    PaymentRequestMAJ: "BETALEN",
    UnSubscribeMAJ: "NIET REGISTREREN",
    SubscribeMaj: "REGISTREREN",
    EventTitle: "Evenement",
    Description: 'Beschrijving',
    AddressDoublePoint: 'Adres:',
    SponsorBy: 'Gesponsord door',
    Inscription: 'Inscription',
    Succes: 'Geslaagd',
    EnterYourFirstName : 'Entrez-votre prénom',
    EnterYourLastName : 'Entrez-votre nom',
    EnterYourEmail : 'Entrez-votre e-mail',
    EnterYourTelNumber : 'Entrez-votre e-mail',
    FirstNameObligatory : 'Prénom',
    EmailObligatory : 'E-mail',
    LastNameObligatory : 'Nom',
    TelObligatory : 'Telefoon',
    TelNotObligatory : 'Telefoon',
    SelectRegion : "Selecteer uw regio",
    Inscrit : "GEREGISTREERD",
    Complet : "VOLLEDIG",
    NosProchainesConférences : "Onze volgende conferenties",
    JeParticipe : "IK NEEM DEEL",
    DevenirMembre : "LID WORDEN",
    ApplicationTitre : "De B19-APP",
    ApplicationDescription1 : "De applicatie van B19, de Business Club van de 21ste eeuw, zal haar leden toestaan om deel te nemen aan het leven van de club en met de hele gemeenschap op een effectieve manier te communiceren.",
    ApplicationDescription2: "Naast de mogelijkheid om de agenda van de activiteiten te raadplegen, kunnen leden zich aanmelden om te ‘Carpoolen’. Een extra opportuniteit om nieuwe mensen te ontmoeten en mee te werken aan het behoud van onze planeet.",
    ApplicationDescription3: "Creëer uw eigen business opportuniteiten dankzij deze tool en ontmoet eenvoudig leden van B19.",
    Adhesion : "Lidmaatschap",
    DevenirMembreQuestion : "Wenst u lid te worden?",
    Moins25ans : "- 25 jaar",
    Free: "Gratis",
    UnePersonne : "1 persoon",
    QuatrePersonnes : "4 personen binnen hetzelfde bedrijf",
    AcheterMaintenant : "BUY NOW",
    Sponsor : "Sponsor",
    BienvenueB19 : "Welkom bij de B19",
    BienvenueDescription1 : "Ontdek een moderne zakenkring die u toegang geeft tot wekelijkse ontbijt- en afterworkconferenties met boeiende sprekers in gezellige sfeer.",
    BienvenueDescription2 : "Uw lidmaatschap geeft u ook toegang tot alle conferenties van de B19 in Waals-Brabant, Luik, Antwerpen, Gent, Bergen-Henegouwen.",
    BienvenueDescription3 : "Als u een verjaardagsfeestje of een vergadering met uw collega's wil organiseren, bieden wij u een evenementenruimte en vergaderzalen die naar wens kunnen worden aangepast.",
    ProposDeNous : "EXTRA UITLEG OVER ONS",
    ProposDeNousDescription : "De B19 nodigt zjjn leden uit deel te nemen aan activiteiten waarbij leden de gelegenheid krijgen hun professioneel en vriendschappelijk netwerk uit te breiden.",
    NotreApplication : "ONZE APP",
    Social : "SOCIAL",
    NousConacter : "Contacteer ons",
    Support : "SUPPORT",
    AutresSitesB19 : "Andere B19 locaties",
    Locattion : "Locatie",
    ToutDroits : "© 2023 | B19 Alle rechten voorbehouden |",
    ConditionsGenerales : " Algemene voorwaarden ",
    FaitPar : "| Made by ",
    Filtre : "FILTER",
    FiltreMin: "Filter",
    RechercherParLangue : "Per taal zoeken",
    RechercherParAdresse : "Per adres zoeken",
    Annuler : "ANNULEREN",
    Rechercher : "ZOEKEN",
    Erreur : "Fout",
    ErreurProduite : "Er is een fout opgetreden",
    ReesayerPlusTard : "Probeer het later eens opnieuw",
    Payer : "BETALEN",
    Sinscrire : "REGISTREREN",
    SeDesinscrire : "NIET REGISTREREN",
    EvenementTitre : "Evenement",
    Description : "Beschrijving",
    Adresse : "Adres:",
    SponsorPar : "Gesponsord door",
    TypeInscription : "Type registratie",
    YoungPackTitle: "Young Pack",
    YoungPack : "Young Pack ( jonger dan 25 jaar - 0EUR)",
    SingleTitle: 'Single',
    SinglePackAnnuel : "Single Jaarlijks (600 EUR btw niet inbegrepen)",
    SinglePackMensuel : "Single Maandelijks (12x 55 EUR btw niet inbegrepen)",
    BusinessPackTitle: "Business Pack",
    BusinessPack : "Business Pack (4 personen binnen eenzelfde bedrijf- 2000 EUR btw niet inbegrepen)",
    CoordonneesPrivees : "Persoonlijke contactgegevens",
    Nom : "Naam",
    EntrezNom : "Voer uw naam in",
    Prénom : "Voornaam",
    EntrezPrenom : "Voer uw voornaam in",
    Email : "E-mail",
    EntrezEmail : "Voer uw e-mailadres in",
    Telephone : "Telefoon",
    EnterTelephone : "Voer uw telefoonnummer in",
    Societe : "Bedrijf",
    EntrezSociete : "Voer uw bedrijfsnaam in",
    NumeroTva : "BTW-nummer",
    EntrezNumeroTva : "Voer uw BTW-nummer in",
    CodePostal : "Postcode",
    EntrezCodePostal : "Voer uw postcode in",
    Ville : "Stad",
    EntrezVille: "Voer uw stadsnaam in",
    AdressePostale : "Adres",
    EntrezAdressePostal : "Straat, Laan, Boulevard",
    MaRegion : "Mijn regio",
    RegionIndiquez : "Gelieve uw regio bij onze clubs aan te duiden",
    Bxl : "Brussel, Waals-Brabant, Namen",
    Luik : "Provincie Luik",
    Anv : "Antwerpen, Limburg",
    Fl : "Vlaanderen",
    Lux : "Groothertogdom Luxemburg",
    DateNaissance : "Geboortedatum",
    Jour : "DD",
    Mois : "MM",
    Annee : "JJJJ",
    ParrainMarraine : "Peter/ Meter",
    EntrezParrainMarraine : "Voer de naam van uw peter/ meter in",
    VotreParrain: "Uw peetvader/peetmoeder: ",
    Collegue1 : "Collega 1",
    Collegue2 : "Collega 2",
    Collegue3 : "Collega 3",
    MoyenPaiement : "Betaalmethode",
    PaiementImmediat : "Onmiddellijke betaling",
    Facture : "Factuur",
    CliquezIciFacturation : " Klik hier als uw factuurgegevens verschillen van uw persoonlijke gegevens",
    CoordonneesFacturation : "Factuurgegevens",
    AdresseFacturation : "Factuuradres",
    EntrezAdresseFacturation : "Voer uw factuuradres in",
    Condition : "Voorwaarde",
    EngagementFacture : "Ik verbind mij ertoe de factuur bij ontvangst te betalen.",
    LuAccepteCondition1 : "Ik heb de ",
    LuAccepteCondition2 : "algemene gebruiksvoorwaarden ",
    LuAccepteCondition3 : "gelezen en aanvaard",
    Continuer : "VERDER GAAN",
    Connexion : "Verbinding",
    PlaceHolderLogin : "Login (e-mailadres)",
    PlaceHolderPassword : "Wachtwoord",
    SeConnecter : "AANMELDEN",
    ForgetPassword : "Wachtwoord vergeten ?",
    PayerEnLigne : "ONLINE BETALEN",
    ErreurLogin : "Er is een fout opgetreden, probeer het later eens opnieuw",
    NousConacterMsg : "Om ons per e-mail te contacteren, gelieve het onderstaande formulier in te vullen.",
    VotreMessage : "Uw bericht",
    Envoyer : "VERZENDEN",
    MotDePasseOublieTitre : "Wachtwoord herstellen",
    MotDePasseOublieMessage : "Gelieve uw e-mailadres in te voeren om uw wachtwoord te herstellen...",
    TokenInvalide : "Ongeldig wachtwoord",
    PasswordLength :"Uw wachtwoord moet ten minste 5 tekens bevatten",
    PasswordPasIdentique : "De wachtwoorden zijn niet identiek",
    EnterToken : "Voer het tijdelijk wachtwoord in (die in uw mailbox zit)",
    CompleteForgetPassword : "Gelieve de onderstaande informatie in te vullen",
    EnterNewPassword : "Voer uw nieuw wachtwoord in",
    ConfirmNewPassword : "Bevestig uw nieuw wachtwoord",
    Confirmer : "BEVESTIGEN",
    MofifierMotDePasse : "Mijn wachtwoord wijzigen",
    TokenExpire : "Wachtwoord verlopen",
    VeuillezRecommencer : "Gelieve opnieuw te beginnen",
    Approuve : "Goedgekeurd",
    MotDePasseBienModifie : "Uw wachtwoord is gewijzigd.",
    EmailModifie: "Votre adresse e-mail a bien été modifié.",
    GaleriePhoto : "Fotogalerij",
    Partager : "Delen",
    ChoisissezPartage : "Kies uw deelmethode:",
    SearchSector : "Per sector zoeken",
    SearchMember : "Een lid zoeken",
    PlaceHolderSearchMember : "Per naam of bedrijfsnaam zoeken",
    ErrorEmail : "Dit lid heeft zijn e-mail niet meegedeeld",
    Home : "HOME",
    Agenda : "AGENDA",
    Member : "LID",
    Galerie : "GALERIJ",
    Profil : "PROFIEL",
    ProfilMin: "Profiel",
    Connect : "AANMELDEN",
    Disconnect : "AFMELDEN",
    MyProfil : "Mijn profiel",
    PersonalInfo : "Persoonlijke gegevens",
    ProInfo : "Professionele gegevens",
    ComplInfo : "Bijkomende informatie",
    DateSociety : "Oprichtingsdatum van het bedrijf:",
    Localisation : "Locatie:",
    LocalisationSociety: "Locatie van het bedrijf",
    EntrerEmailPlaceholder: "Voer uw nieuw e-mailadres in",
    ReEntrerEmailPlaceholder: "Bevestig uw nieuw e-mailadres",
    SizeSociety : "Omvang van het bedrijf:",
    ModifyButton : "WIJZIGEN",
    ModifyProfil : "MIJN PROFIEL WIJZIGEN",
    ModifyProfilMin: "Minj profiel wijzigen",
    ModifyPassword : "MIJN WACHTWOORD WIJZIGEN",
    ModifyEmail : "MIJN E-MAIL WIJZIGEN",
    ModifyEmailMin : "Mijn e-mail wijzigen",
    Commentray : "Opmerkingen ?",
    Poste: "Baanstatus",
    Sector : "Sector",
    SectorDescription : "Gelieve uw activiteitensector te vermelden",
    Enregistrer : "BEWAREN",
    NewPicture : "NIEUWE FOTO",
    CancelPayment : "BETALING GEWEIGERD",
    SuccesPayment : "BETALING GOEDGEKEURD",
    Inscription : "Registreren",
    Success : "Goedgekeurd",
    EmailAlreadySubscribe : "Uw e-mailadres is al geregistreerd voor dit evenement.",
    EmailMember : "Uw e-mail is geregistreerd als lid, gelieve in te loggen.",
    EmailNotSame: "De e-mailadressen zijn niet identiek",
    NoEventIntoSection: "Voorlopig geen conferentie...",
    NoAmbassadorIntoSection: "Momenteel geen ambassadeurs...",
    News: "Nieuw !",
    Facturation: "Factuur",
    Send: "Aanvraag bevestigd",
    FacturationSend: "Een factuur wordt u zo snel mogelijk toegestuurd.",
    Cotisation: "Status lidmaatschap",
    CotisationMessage: "U bent niet langer in orde",
    CotisationIndication: "Om verder deel te nemen aan de B19 evenementen, gelieve opnieuw in te loggen.",
    InscriptionSucces: "Welkom bij de B19, uw inloggegevens zijn per e-mail naar u verzonden.",
    AskForAdhesion: "Aanvraag lidmaatschap",
    RegistrationTypeNotSelect: "Het type registratie is niet geselecteerd",
    FirstNameMissing: "Voornaam ontbreekt",
    Caract: "Geen ongeldige karakters (@#{}..)",
    FirstNameNumber: "Uw voornaam moet tussen 2 en 30 karakters bevatten",
    LastNameMissing : "Achternaam ontbreekt",
    LastNameNumber : "Uw naam moet tussen 2 en 30 karakters bevatten",
    EmailMissing : "E-mailadres ontbreekt",
    EmailInvalid : "E-mailadres ongeldig",
    EmailNumber : "Uw E-mail mag niet meer dan 60 karakters bevatten",
    TelMissing : "Telefoonnummer ontbreekt",
    TelNumber : "Uw telefoonnummer mag niet meer dan 25 cijfers bevatten",
    MessageMissing : "Voer uw bericht in",
    MessageNumber : "Uw bericht moet tussen 40 en 500 karakters bevatten",
    ErrorVerif : "Er is een fout opgetreden tijdens de verificatie",
    EmailNotExist : "E-mailadres ongeldig",
    PasswordMissing : "Wachtwoord ontbreekt",
    PasswordNotGood : "Verkeerd wachtwoord",
    BusinessNotApproved : "Uw ‘Business pack’-registratie is niet goedgekeurd",
    BusinessNotPay : "De verantwoordelijke voor het “Business pack” keurde de betaling ervan niet goed",
    CoupleNotApproved : "Uw ‘Couple pack’-registratie is niet goedgekeurd",
    CoupleNotPay : "De verantwoordelijke voor het “Couple pack” heeft de betaling niet goedgekeurd",
    ErrorContactStaff : "Neem contact op met het B19 personeel, er is een fout opgetreden met uw account",
    NotApproved : "Uw account is niet goedgekeurd",
    NotPay : "Uw registratie is nog niet goedgekeurd",
    FormatDateNotGood : "Voer een datum in DD/MM/JJJJ-formaat in",
    DateFromFutur : "Het lijkt erop dat u in de toekomst geboren bent",
    AgeUnder25 : "U moet jonger zijn dan 25 jaar voor het Young pack",
    RegistrationTypeNotSelect : "Het type registratie is niet geselecteerd",
    DateMissing : "Datum ontbreekt",
    SocietyMissing : "Bedrijfsnaam ontbreekt",
    TvaMissing : "BTW-nummer ontbreekt",
    ZipCodeMissing : "Postcode ontbreekt",
    CityMissing : "Stadsnaam ontbreekt",
    StreetMissing : "Adres ontbreekt",
    RegionMissing : "Regio ontbreekt",
    ColleagueMissing : "Collega ontbreekt",
    ChoosePaymentMethod: "Gelieve een betaalmethode te kiezen.",
    PaymentMethodNotSelect : "Betaalmethode niet geselecteerd",
    CheckBoxEngagement : "Het vakje betalingsverplichting factuur is niet aangevinkt",
    CheckBoxGeneralConditionOfUse : "Het vakje van de algemene gebruiksvoorwaarden is niet aangevinkt",
    MaxNumberColleague : "Drie collega's zijn vereist voor een 'Business'-registratie",
    EmailColleagueExist : "Het e-mailadres van een van de collega's bestaat al",
    EmailExist : "Het e-mailadres bestaat al",
    EnterName : "Voer een naam in",
    NoAccessPage : "U heeft geen toegang tot deze pagina",
    CotisationOk : "U bent in orde met de betaling van uw lidmaatschap",
    ErrorHappened : "Er is een fout opgetreden, log in om deze pagina te openen of probeer het later opnieuw",
    EnterEmail : "Voer een e-mailadres in",
    ErrorUpdateEmail : "Er is een fout opgetreden tijdens de wijziging van uw e-mailadres",
    VerifyDate : "Controleer uw geboortejaar",
    AccesTokenMissing : "Er is een fout opgetreden, gelieve opnieuw te verbinden",
    ImageErrorUpload : "Er is een fout opgetreden bij het uploaden van de afbeelding",
    EnterPassword : "Voer een wachtwoord in",
    PasswordLength : "Voer minstens 6 karakters in",
    Space: " ",
    TypeOfInscription: "Type registratie",
    TypeOfMotivation: "Motivatie pitch",
    TypeOfMotivationPlaceholder: "Wij hebben te veel aanvragen voor Young members. Daarvoor vragen wij je om je motivatie hier in te vullen",
    ErrorNetwork: "Netwerkfout, probeer het opnieuw",
    ErrorStaff: "Er is een fout opgetreden, neem contact op met de B19 staff",
    AlreadyOrderCotisation: "Het lijkt erop dat u uw bijdrage al betaald heeft of dat u geen toegang heeft tot deze pagina",
    SendMail: "Gestuurd",
    SendSucces: "Uw bericht is succesvol verzonden.",
    MissingRegion: "Regio ontbreekt",
    Contact: "Contact",
    ContactMaj: "CONTACT",
    NousContacter: "Neem contact met ons op",
    NousContacterRegion: "Om ons per e-mail of telefoon te contacteren, gelieve uw regio aan te duiden.",
    Immobilier: "Immobiliën",
    Banques: "Bank, verzekering, financië",
    Industriel: "Industrie",
    It: "IT",
    Biens: "Goederen en diensten voor bedrijven (B2B)",
    Commerces: "Kleinhandel (B2C)",
    Avocat: "Advocaat en anderen liberale beroepen",
    Consultance: "Consultancy/Training",
    Autres: "Andere",
    EmailNotSave: "Dit lid heeft zijn/ haar e-mail niet meegedeeld.",
    IndiquerSizeOfSociety: "Gelieve de omvang van het bedrijf aan te geven",
    less10 : "Minder dan 10 werknemers",
    between10: "Tussen 10 en 50 werknemers",
    to50: "50 tot 200 werknemers",
    to200: "Meer dan 200 werknemers",
    Belgique: "België",
    VerifyDatePlz: "Gelieve het jaar te controleren",
    ProfilUpdate: "Uw profiel is bijgewerkt.",
    Parrainnage: "GESPONSORD WORDEN",
    VoirPlus: "ZIE MEER",
    ErrorSharing : "U moet akkoord gaan met het delen van gegevens",
    PlaceholderInvitation: "Optioneel: geef aan wie u heeft uitgenodigd",

  },
};
